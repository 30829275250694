import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Typography,
  Button,
  Divider,
  Modal,
} from "antd";
import SelectLocation from "components/SelectLocation";
import AddressQuestions from "./AddressQuestions";
import { isEmpty, set } from "lodash";
import { useSignupContext } from "context/SignUpContext";
import { fixtures } from "../../fixtures";
import { PROGRAM_NIDA_CODE } from "../../../../configs/constants";

const { Text, Title } = Typography;

const addressHelpMessage =
  "You must either select organization address, an existing shipping address, or enter a new shipping address and click 'Add'";

export default function SamplingLocationQuestions({
  index,
  form,
  program,
  samplingLocation,
  shippingLocations,
  handleNewShippingLocation,
}) {
  const [showShippingLocations, setShowShippingLocation] = useState(true);
  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState();
  const [shippingLocationRef, setShippingLocationRef] = useState({});
  const [excludeFromProgram, setExcludeFromProgram] = useState(false);

  const existingLocation = !isEmpty(samplingLocation);

  const { variant } = useSignupContext();
  const formFixtures = fixtures[variant];

  useEffect(() => {
    // Note that initialValues cannot be updated by setState dynamically, you should use setFieldsValue in that situation.
    // https://ant.design/components/form/
    if (shippingLocationRef) {
      const currentValues = form.getFieldsValue();
      const formPath = ["sampling_locations", index];
      set(
        currentValues,
        [...formPath, "shipping_location_ref"],
        shippingLocationRef?.ref?.toString()
      );
      set(
        currentValues,
        [...formPath, "shipping_location_ref_type"],
        shippingLocationRef?.status
      );
      if (shippingLocationRef?.new_shipping_location) {
        // Inject new address values from the modal form to the top-level form
        // so that they can be used on top-level form submission
        set(
          currentValues,
          [...formPath, "new_shipping_location"],
          shippingLocationRef?.new_shipping_location
        );
      }
      form.setFieldsValue(currentValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingLocationRef]);

  const handleLocationSelected = (location) => {
    if (location === "new_site") {
      setModalContent(
        <Form layout={"vertical"} onFinish={handleAddShippingLocation}>
          <AddressQuestions required={true} />
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Row>
        </Form>
      );
      setModalOpen(true);
    } else {
      const locationRef = location.id ? location.id : location.name;
      setShippingLocationRef({ ref: locationRef, status: "EXISTING" });
    }
  };

  const handleAddShippingLocation = (location) => {
    setModalOpen(false);
    setShippingLocationRef({
      ref: location.name, // Name or ID to use to lookup the actual address fields
      status: "NEW", // Method used to determine address (NEW / ORG  / EXISTING)
      new_shipping_location: location, // Address data from the modal form
    });
    handleNewShippingLocation(location);
  };

  const onChangeUseOrganizationAddress = (event) => {
    const useOrg = event.target.checked;
    setShowShippingLocation(!useOrg);
    if (useOrg) {
      setShippingLocationRef({ ref: "ORGANIZATION", status: "ORGANIZATION" });
    } else {
      setShippingLocationRef({});
    }
  };

  const onChangeExcludeFromProgram = (event) => {
    const excludeLocation = event.target.checked;
    setExcludeFromProgram(excludeLocation);
  };

  return (
    <>
      <Divider className="mt-1" />
      <Row className="mb-1">
        <Col>
          <Title level={4}>
            {samplingLocation?.sampling_location_name ??
              "New Sampling Location"}
          </Title>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={existingLocation ? 8 : 12}>
          <Form.Item
            label="Sampling Location Name"
            data-cy="unique_sampling_location_name"
            name={[index, "sampling_location_name"]}
            initialValue={samplingLocation?.sampling_location_name}
            help={
              !formFixtures.isFormForExistingCustomers &&
              "This should be a unique name across locations, e.g. MOT 2, South Influent, Plant No 4"
            }
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input
              placeholder="Enter sampling location name"
              disabled={samplingLocation?.sampling_location_name?.length > 0}
            />
          </Form.Item>
        </Col>
        <Col span={existingLocation ? 8 : 12}>
          <Form.Item
            label="Estimated population served"
            data-cy="population_estimate_area"
            name={[index, "estimated_population_served"]}
            initialValue={samplingLocation?.estimated_population_served}
            help={
              !formFixtures.isFormForExistingCustomers &&
              "Population estimate for this sample catchment area (no commas)"
            }
            rules={[
              {
                required:
                  !(existingLocation || excludeFromProgram) ||
                  program === PROGRAM_NIDA_CODE,
                message: "Enter Estimated population served",
                pattern: new RegExp(/^[0-9]+$/),
              },
            ]}
          >
            <Input
              disabled={
                (existingLocation || excludeFromProgram) &&
                program !== PROGRAM_NIDA_CODE
              }
              placeholder="Enter estimated population served"
            />
          </Form.Item>
        </Col>
        <Col span={existingLocation ? 8 : 12}>
          <Form.Item
            label="Capacity Flow (MGD)"
            data-cy="capacity_mgd_area"
            name={[index, "capacity_mgd"]}
            initialValue={samplingLocation?.capacity_mgd}
            help={
              !formFixtures.isFormForExistingCustomers &&
              "Capacity flow for this sampling location"
            }
            rules={[
              {
                required:
                  !(existingLocation || excludeFromProgram) ||
                  program === PROGRAM_NIDA_CODE,
                message: "Enter estimated capacity flow (mgd)",
                pattern: new RegExp("^\\d+(\\.\\d+)?$"),
              },
            ]}
          >
            <Input
              disabled={
                (existingLocation || excludeFromProgram) &&
                program !== PROGRAM_NIDA_CODE
              }
              placeholder="Enter estimated capacity flow (mgd)"
            />
          </Form.Item>
        </Col>
      </Row>
      {existingLocation && (
        <Col span={8}>
          <Form.Item
            name={[index, "exclude_from_program"]}
            valuePropName="checked"
          >
            <Checkbox onChange={onChangeExcludeFromProgram}>
              <Text>
                Exclude this location from the {formFixtures.programName}
              </Text>
            </Checkbox>
          </Form.Item>
        </Col>
      )}

      {!excludeFromProgram && (
        <>
          <Row className="mt-4">
            <Col>
              <Title level={5}>Select or add a shipping address</Title>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Item
                hidden={true}
                name={[index, "shipping_location_ref_type"]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={[index, "use_organization_address"]}
                valuePropName="checked"
              >
                <Checkbox
                  data-cy="use-organization-address"
                  onChange={(e) => onChangeUseOrganizationAddress(e)}
                >
                  Use organization address as shipping address
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row data-cy="select-shipping_location">
            <Col span={24}>
              {showShippingLocations && (
                <SelectLocation
                  locations={shippingLocations}
                  locationType="shipping"
                  showTitle={false}
                  onLocationSelected={handleLocationSelected}
                  formProps={{
                    name: [index, "shipping_location_ref"],
                    rules: [
                      {
                        required: true,
                        message: addressHelpMessage,
                      },
                    ],
                  }}
                />
              )}
            </Col>
          </Row>
          <Form.Item noStyle name={[index, "new_shipping_location"]}>
            <Input type="hidden" />
          </Form.Item>
          <Modal
            visible={modalOpen}
            width={"75%"}
            footer={[]}
            onCancel={() => setModalOpen(false)}
            destroyOnClose
          >
            <div>{modalContent}</div>
          </Modal>
        </>
      )}
    </>
  );
}
