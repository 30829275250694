import { Form, Select, Col, Row } from "antd";
import Title from "antd/lib/typography/Title";

const options = [
  {
    key: "Biobot",
    value: "Biobot",
  },
];

const KitProvider = () => {
  return (
    <>
      <Row>
        <Col span={16}>
          <Title level={3}>Kit Provider</Title>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col span={8}>
          <Form.Item
            name="kit_provider"
            label="Kit Provider"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select data-cy="kit-provider" options={options} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default KitProvider;
