import React from "react";
import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Form, Select, Input, Row, Col, Button } from "antd";
import { orderServiceCreate } from "../../../api/orderService";
const { TextArea } = Input;
const { Option } = Select;

const LAB_PROTOCOL_VERSIONS = "lab-protocol-version";
const STANDARD_CURVE_VERSIONS = "standard-curve-version";
const UPLOAD_SCHEMA_VERSIONS = "upload-schema-version";
const ANALYSIS_PROTOCOL_VERSIONS = "analysis-protocol-version";

const VERSION_NAME = "version_name";
const DESCRIPTION = "description";
const LAB_METHOD = "lab_method";
const UPLOAD_TYPE = "upload_type";

const CreateProtocolPage = () => {
  const [form] = Form.useForm();
  const [selectedModel, setSelectedModel] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [resultMsg, setResultMsg] = useState("");

  // Always put description last so it can have more room
  const protocolModels = [
    {
      name: LAB_PROTOCOL_VERSIONS,
      fields: [VERSION_NAME, LAB_METHOD, DESCRIPTION],
    },
    {
      name: STANDARD_CURVE_VERSIONS,
      fields: [VERSION_NAME, DESCRIPTION],
    },
    {
      name: UPLOAD_SCHEMA_VERSIONS,
      fields: [VERSION_NAME, UPLOAD_TYPE, DESCRIPTION],
    },
    {
      name: ANALYSIS_PROTOCOL_VERSIONS,
      fields: [VERSION_NAME, DESCRIPTION],
    },
  ];

  const handleModelChange = (value) => {
    setSelectedModel(value);
  };

  // Make sure to refresh the rest of form when changing the model
  useEffect(() => {
    const selectedModelData = protocolModels.find(
      (model) => model.name === selectedModel
    );

    form.resetFields([VERSION_NAME, DESCRIPTION, LAB_METHOD, UPLOAD_TYPE]);
    setResultMsg("");

    if (selectedModelData) {
      setFormFields(selectedModelData.fields);
    } else {
      setFormFields([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModel]);

  const customValidator = async (rule, value) => {
    const fieldName = rule.field;
    // Use a different rule depending on the field
    switch (fieldName) {
      case VERSION_NAME:
        return await validateVersionName(value);
      case LAB_METHOD:
        return await validateLabMethod(value);
      case UPLOAD_TYPE:
        return await validateUploadType(value);
      default:
        // No validation for "description"
        return Promise.resolve();
    }
  };

  const validateVersionName = async (value) => {
    // Version naming format differs depending on the type of protocol
    const typeName = form.getFieldValue("protocol_type");

    let versionPattern = /^$/;
    let expectedPattern = "unknown";
    switch (typeName) {
      case UPLOAD_SCHEMA_VERSIONS:
        versionPattern = /^v\d+(\.\d+)?$/;
        expectedPattern = "v##(.##)";
        break;
      case STANDARD_CURVE_VERSIONS:
        versionPattern = /^[a-zA-Z]+-v\d+$/;
        expectedPattern = "<assay>-v##";
        break;
      case ANALYSIS_PROTOCOL_VERSIONS:
      case LAB_PROTOCOL_VERSIONS:
        versionPattern = /^[a-zA-Z]+-v\d+(\.\d+)?$/;
        expectedPattern = "<assay>-v##(.##)";
        break;
      default:
        return Promise.reject(
          `Unknown version name pattern, cannot validate. Please contact eng-support for help.`
        );
    }

    if (versionPattern.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        `Version name does not match expected format, please use ${expectedPattern}`
      );
    }
  };

  const validateLabMethod = async (value) => {
    // This should match the options in lab-protocol-version.settings.json
    switch (value) {
      case "qpcr":
      case "ddpcr":
      case "lcms":
        return Promise.resolve();
      default:
        return Promise.reject(
          `Invalid lab method, use one of [qpcr, ddpcr, lcms]`
        );
    }
  };

  const validateUploadType = async (value) => {
    // This should match the options in upload_schema_version.settings.json
    switch (value) {
      case "qpcr_xlsx":
      case "tracefinder_csv":
      case "ddpcr_csv":
        return Promise.resolve();
      default:
        return Promise.reject(
          `Invalid upload type, use one of [qpcr_xlsx, tracefinder_csv, ddpcr_csv]`
        );
    }
  };

  const handleSubmit = async () => {
    // Validate all fields in the form
    try {
      const formData = await form.validateFields();
      setFieldErrors({});

      // Submit the new protocol
      const protocolType = formData.protocol_type;
      delete formData.protocol_type;
      await orderServiceCreate({ model: `${protocolType}s`, data: formData })
        .then(() => {
          form.resetFields();
          setResultMsg(
            `Successfully created ${protocolType}: ${formData.version_name} \u{2192} ${formData.description}`
          );
        })
        .catch((e) => {
          setResultMsg(
            `Error attempting to create ${protocolType}, please consult eng-support for assistance.`
          );
        });
    } catch (e) {
      console.log("Validation failed:", e);
      // Reset any errors from last attempt
      const newFieldErrors = {};
      for (const errField of e.errorFields) {
        newFieldErrors[errField.name[0]] = errField.errors.join(", ");
      }
      setFieldErrors(newFieldErrors);
    }
  };

  return (
    <>
      <Row>
        <Col width="80%">
          <Form form={form} layout="horizontal">
            <Form.Item label="Protocol type" name="protocol_type">
              <Select
                value={selectedModel || undefined}
                onChange={handleModelChange}
                placeholder="Please select protocol type"
              >
                {protocolModels.map((model) => (
                  <Option key={model.name} value={model.name}>
                    {model.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {formFields.map((field, index) => (
              <Form.Item
                key={index}
                name={field}
                label={field}
                rules={[
                  { required: true, message: "All fields are required" },
                  { validator: customValidator },
                ]}
                validateStatus={fieldErrors[field]?.length ? "error" : ""}
                help={fieldErrors[field]}
              >
                {field === "description" ? <TextArea /> : <Input />}
              </Form.Item>
            ))}
            <Button id="form-submit" type="primary" onClick={handleSubmit}>
              Create new Protocol
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="mt-2">{resultMsg}</Row>
    </>
  );
};

export default withRouter(CreateProtocolPage);
