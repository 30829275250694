import { Button, Col, Row, Tabs, Typography } from "antd";
import Title from "antd/lib/typography/Title";

import HistoricalQuantTable from "./HistoricalQuantTable";
import HistoricalQuantChart from "./HistoricalQuantChart";
import AliquotStatuses from "./AliquotStatuses";

import ReviewWorkflowBreadcrumbs from "../ReviewWorkflowBreadcrumbs";
import {
  UpdateKitUnderReviewAction,
  UpdateOverrideAction,
} from "reducers/reducerManualReview";
import React, { useContext, useState } from "react";
import { KitMetaPartial } from "../types/orderService";
import { createKitReviewOrder, missingLogPredicate } from "../util";
import _ from "lodash";
import { fetchKitsForLocation, useKitList } from "api/kit";
import moment from "moment/moment";
import LoadingPage from "../LoadingPage/LoadingPage";
import { useQuery } from "react-query";
import { fetchReviewDataForKits } from "api/pipelinesAPI/kits";
import {
  AliquotId,
  AliquotStatus,
  AssayName,
  KitName,
  ManualReviewStatus,
} from "api/pipelinesAPI/types";
import SampleDetails from "./SampleDetails";
import LocationDetails from "./LocationDetails";
import { ReviewReducerContext } from "../reviewContext";

const AliquotReview = () => {
  const { state, dispatch } = useContext(ReviewReducerContext);
  const [highlightedAliquotId, setHighlightedAliquotId] = useState<
    number | undefined
  >();

  if (_.isNil(state.activeAssayName)) {
    throw new Error("Expected active assay");
  }

  const kitNameUnderReview = state.activeAssayState().kitNameUnderReview;

  if (_.isNil(kitNameUnderReview)) {
    throw new Error("Expected active kit under review");
  }

  const { data: kitMetas } = useKitList(
    {
      _where: {
        kit_id: kitNameUnderReview,
      },
      _joins: ["sample.sampling_location_id"],
    },
    {}
  );

  const kitMeta = (kitMetas || [])[0];
  const locationId = kitMeta?.sample?.sampling_location_id?.id;
  const collectionCutoff = moment.utc().subtract(1, "months");

  const { data: kitsFromLoc } = useQuery(
    ["kitsByLoc", locationId],
    (_) => fetchKitsForLocation(locationId, collectionCutoff),
    { enabled: !_.isNil(locationId) }
  );

  const kitNamesFromLoc = kitsFromLoc?.map((k: any) => k.kit_id);

  const { data: locReviewData } = useQuery(
    ["locReviewData", locationId],
    (_) =>
      fetchReviewDataForKits(
        state.activeAssayName as AssayName,
        kitNamesFromLoc || []
      ),
    { enabled: !_.isNil(kitNamesFromLoc) }
  );

  if (_.isNil(kitsFromLoc) || _.isNil(locReviewData)) {
    return (
      <LoadingPage message={"Loading review data for sampling location"} />
    );
  }

  const kitMap: Record<KitName, KitMetaPartial> = {};
  kitsFromLoc.forEach((kit: any) => {
    kitMap[kit.kit_id] = kit as KitMetaPartial;
  });

  const targetQuants = locReviewData.data.target_quants;

  const statuses = locReviewData.data.aliquot_statuses;
  const activeKitStatuses = statuses.filter(
    (s) => s.qpcr_aliquot.tube.kit.kit_name === kitNameUnderReview
  );
  const statusMap: Record<AliquotId, AliquotStatus> = {};
  statuses.forEach((s) => {
    statusMap[s.qpcr_aliquot.qpcr_aliquot_id] = s;
  });

  const uniqueTargetNames = new Set(
    targetQuants.map((q) => q.primer_target_protocol.primer_target_name)
  );

  const pmmvMap: Record<number, number> = {};
  targetQuants.forEach((q) => {
    if (q.primer_target_protocol.primer_target_name === "pmmv") {
      pmmvMap[q.qpcr_aliquot_id] = q.copies_per_ml_sewage_adjusted || 0.0;
    }
  });

  const targetTabs = [...uniqueTargetNames].map((targetName) => {
    const quants = targetQuants.filter(
      (q) => q.primer_target_protocol.primer_target_name === targetName
    );

    return {
      label: targetName,
      key: targetName + "-historical-quant-tab",
      children: (
        <HistoricalQuantTable
          kitNameUnderReview={kitNameUnderReview}
          targetName={targetName}
          targetQuants={quants}
          statusMap={statusMap}
          kitMap={kitMap}
          pmmvMap={pmmvMap}
          onHighlightedRowChange={(aliquotId) =>
            setHighlightedAliquotId(aliquotId)
          }
        />
      ),
    };
  });

  const assayState = state.activeAssayState();
  const anyOverrides = statuses.some((s) => {
    const override = assayState.getOverride(s.aliquot_status_id);
    return override.status !== ManualReviewStatus.STATUS_NO_OVERRIDE;
  });

  const reviewableStatuses = state
    .activeAssayState()
    .pendingAliquotStatuses.filter(_.negate(missingLogPredicate));
  const kitReviewOrder = createKitReviewOrder(reviewableStatuses);
  const numKitsReviewed = assayState.countKitsWithStatusOverrides(
    kitReviewOrder,
    reviewableStatuses
  );

  const handleNextKit = () => {
    const action = new UpdateKitUnderReviewAction(
      state.activeAssayName as AssayName,
      state.activeAssayState().nextKit(kitReviewOrder)
    );

    dispatch(action);
  };

  /**
   * The majority of aliquots that are pending review will be approved after a quick visual glance at the quant charts.
   * This button speeds up the workflow by reducing the amount of scrolling and clicking the user has to do after
   * verifying the data in the chart. It will be disabled if there is more than one aliquot (e.g. a rerun review),
   * which requires additional action aside from approving the aliquots under review.
   */
  const quickApprove = () => {
    const status = activeKitStatuses[0];

    dispatch(
      new UpdateOverrideAction(
        state.activeAssayName as AssayName,
        status.aliquot_status_id,
        ManualReviewStatus.STATUS_APPROVED,
        null
      )
    );

    handleNextKit();
  };

  return (
    <>
      <Row justify="space-between" className="ml-3">
        <Col>
          <ReviewWorkflowBreadcrumbs />
        </Col>
        {
          <Col>
            <Typography.Text>
              {numKitsReviewed} of {kitReviewOrder.length} kits reviewed
            </Typography.Text>
            <Button
              type={anyOverrides ? "primary" : "default"}
              onClick={(_) => handleNextKit()}
              className="ml-3"
            >
              {anyOverrides ? "Next" : "Skip"}
            </Button>
          </Col>
        }
      </Row>
      <Row className="ml-3 mr-3 mt-3">
        <Col span={24}>
          <SampleDetails kitMeta={kitMeta} />
        </Col>
      </Row>
      <Row className="ml-3 mr-3 mt-5">
        <Col span={24}>
          <AliquotStatuses aliquotStatuses={activeKitStatuses} />
        </Col>
      </Row>
      <Row className="ml-3 mr-3 mt-5">
        <Col span={24}>
          <LocationDetails kitMeta={kitMeta} />
        </Col>
      </Row>
      <Row className="ml-3 mr-3 mt-5">
        <Col span={24}>
          <Title level={3}>Historical Results</Title>
          <HistoricalQuantChart
            kitNameUnderReview={kitNameUnderReview}
            targetQuants={targetQuants}
            statusMap={statusMap}
            kitMap={kitMap}
            highlightedAliquotId={highlightedAliquotId}
          />
        </Col>
      </Row>
      <Row justify="end" className="ml-3 mr-3 mt-3">
        <Col>
          <Button
            type="primary"
            disabled={activeKitStatuses?.length !== 1}
            onClick={quickApprove}
          >
            Quick Approve
          </Button>
        </Col>
      </Row>
      <Row className="ml-3 mr-3 mt-3">
        <Col span={24}>
          <Tabs items={targetTabs} />
        </Col>
      </Row>
    </>
  );
};

export default AliquotReview;
