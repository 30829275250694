import axios from "axios";
import { useState, useEffect } from "react";
import { Row, Col, Typography, AutoComplete, Input, message } from "antd";
import qs from "qs";

const { Text } = Typography;
const initKitOptionsUrl = `${process.env.REACT_APP_API_URL}kits`;

function KitSelector({ onKitSelected, className }) {
  const [loading, setLoading] = useState(false);
  const [kitLookup, setKitLookup] = useState();
  const [kitOptions, setKitOptions] = useState([]);

  const axiosHeader = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  const generateAxiosParams = (kitLookup, cancelToken) => {
    return {
      headers: axiosHeader,
      cancelToken: cancelToken,
      params: [
        { kit_id_contains: kitLookup },
        { shipping_kit_id_contains: kitLookup },
      ],
      paramsSerializer: (params) => {
        return qs.stringify({
          _where: {
            _or: params,
          },
        });
      },
    };
  };

  const generateKitOptions = (kits, userInput) => {
    const options = kits
      .filter(({ kit_id, shipping_kit_id }) => kit_id || shipping_kit_id)
      .map(({ id, kit_id, shipping_kit_id, date_received }) => {
        let label = null;

        if (kit_id && shipping_kit_id) {
          label = `${kit_id} - ${shipping_kit_id}`;
        } else if (shipping_kit_id) {
          label = `No Kit ID - ${shipping_kit_id}`;
        } else if (kit_id) {
          label = `${kit_id} - No Therapak ID`;
        }

        return {
          label: label,
          value: id,
          kit_id: kit_id,
          shipping_kit_id: shipping_kit_id,
          date_received: date_received,
        };
      });

    return options;
  };

  const filterKitOptions = (options, userInput) => {
    const uppercaseInput = userInput.toUpperCase();
    const filteredOptions = options
      .filter(({ kit_id, shipping_kit_id }) => {
        return (
          kit_id?.startsWith(uppercaseInput) ||
          shipping_kit_id?.startsWith(shipping_kit_id)
        );
      })
      .sort(({ label }) => label);

    return filteredOptions;
  };

  useEffect(() => {
    if (!kitLookup || kitLookup.length < 2) {
      setKitOptions([]);
    } else {
      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      setLoading(true);
      const params = generateAxiosParams(kitLookup, source.token);
      axios
        .get(initKitOptionsUrl, params)
        .then((res) => {
          const options = generateKitOptions(res.data, kitLookup);
          setKitOptions(filterKitOptions(options, kitLookup));
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          if (!axios.isCancel(e)) {
            message.error("Something went wrong!");
            setLoading(false);
          }
        });

      return () => {
        source.cancel();
      };
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitLookup]);

  const handleSelected = (value, option) => {
    setKitLookup(null);
    onKitSelected(option);
  };

  const handleSearch = (value, event) => {
    const exactMatch = kitOptions.filter((option) => {
      const matchesKitExactly =
        option.kit_id?.toUpperCase() === value?.toUpperCase();
      const matchesShippingExactly =
        option.shipping_kit_id?.toUpperCase() === value?.toUpperCase();
      return matchesKitExactly || matchesShippingExactly;
    })[0];

    if (exactMatch) {
      setKitLookup(null);
      onKitSelected(exactMatch);
    }
  };

  return (
    <div className={className}>
      <Text>Kit IDs*</Text>
      <Row>
        <Col span={8}>
          <AutoComplete
            options={kitOptions}
            style={{ width: "100%" }}
            value={kitLookup}
            onChange={setKitLookup}
            onSelect={handleSelected}
            notFoundContent="No Kit ID or Shipping ID found"
          >
            <Input.Search
              placeholder="Find Kit ID by ID, Shipping ID, or Internal ID"
              loading={loading}
              enterButton
              onSearch={handleSearch}
            />
          </AutoComplete>
        </Col>
      </Row>
    </div>
  );
}

export default KitSelector;
