import { Card, Row, Col, Form, Input, Button } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import SingleUploadFormFields from "./SingleUploadFormFields";
import { requiredRule } from "utils/rules";

const SingleBatchFormFields = ({
  batchFieldIdx,
  onRemove,
  onUpload,
  assayName,
  acceptTypes,
  ...restBatchField
}) => {
  return (
    <Row gutter={8}>
      <Col span={21}>
        <Card className="my-2">
          {/* Batch Name */}
          <Form.Item
            name={[batchFieldIdx, "batch_name"]}
            label="Batch Name"
            wrapperCol={{ span: 10 }}
            rules={[requiredRule("Batch Name is required")]}
            {...restBatchField}
          >
            <Input placeholder="e.g. Plate 893A PMMV or Plate893A_PMMV_Cy0" />
          </Form.Item>
          {/* Upload List */}
          <Form.List
            name={[batchFieldIdx, "uploads"]}
            initialValue={[{ plate_name: "targets,controls" }]}
          >
            {(fields, { add: addUpload, remove: removeUpload }, { errors }) => (
              <>
                {fields.map(({ key, name, ...restUploadField }) => (
                  <SingleUploadFormFields
                    key={key}
                    acceptTypes={acceptTypes}
                    batchFieldIdx={batchFieldIdx}
                    uploadFieldIdx={name}
                    onUpload={onUpload}
                    onRemove={() => removeUpload(name)}
                    {...restUploadField}
                  />
                ))}
                <Form.ErrorList errors={errors} />
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => addUpload()}
                >
                  Add Plate
                </Button>
              </>
            )}
          </Form.List>
        </Card>
      </Col>
      <Col className="flex items-center" span={3}>
        <Button
          icon={<MinusCircleOutlined />}
          type="text"
          danger
          onClick={onRemove}
        >
          Remove Batch
        </Button>
      </Col>
    </Row>
  );
};

export default SingleBatchFormFields;
