import { Tooltip, Button } from "antd";
import { columnSearchProps } from "components/Table/SearchFilter";
import {
  DDPCR_UPLOAD_ASSAY_OPTIONS,
  PCR_UPLOAD_ASSAY_OPTIONS,
  PLATE_NAME_OPTIONS,
} from "api/fileUpload";
import {
  METHOD_TEXT_CONTAINS,
  METHOD_DAY_RANGE_EXCLUSIVE_END,
  strapiFilterProps,
} from "components/Table/StrapiFilter";
import { toLocaleString } from "utils/time";

const renderOption = (options) => (optName) =>
  options.find((opt) => opt.value === optName)?.label;

const optionsToFilters = (options) =>
  options.map(({ label, value }) => ({ text: label, value }));

const renderVersion = (version) => (
  <Tooltip title={version?.description}>{version?.version_name}</Tooltip>
);

export const getPcrUploadTableColumns = ({ handleFileClick }) => [
  {
    title: "Form Submitted By",
    dataIndex: "form_submitted_by",
  },
  {
    title: "PCR Results File",
    key: "action",
    render: (_, record) => (
      <Button type="link" onClick={() => handleFileClick(record)}>
        {record?.file_name}
      </Button>
    ),
  },
  {
    title: "Assay Name",
    dataIndex: "assay_name",
    width: 125,
    filters: optionsToFilters(
      PCR_UPLOAD_ASSAY_OPTIONS.concat(DDPCR_UPLOAD_ASSAY_OPTIONS)
    ),
    render: renderOption(
      PCR_UPLOAD_ASSAY_OPTIONS.concat(DDPCR_UPLOAD_ASSAY_OPTIONS)
    ),
  },
  {
    title: "Batch Name",
    width: 110,
    dataIndex: "batch_name",
    ...columnSearchProps({ placeholder: "Search by Batch Name" }),
  },
  {
    title: "Plate Name",
    dataIndex: "plate_name",
    filters: optionsToFilters(PLATE_NAME_OPTIONS),
    render: renderOption(PLATE_NAME_OPTIONS),
  },
  {
    title: "PCR Ran By",
    dataIndex: "qpcr_ran_by",
    ...columnSearchProps({ placeholder: "Search by Name or Initials" }),
  },
  {
    title: "Date of PCR",
    width: 120,
    dataIndex: "date_of_qpcr",
    defaultSortOrder: "descend",
    sorter: true,
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
    }),
  },
  {
    title: "Flagged Aliquots",
    width: 160,
    dataIndex: "flagged_aliquots",
    render: (aliquots) => <>{(aliquots || []).join(", ")}</>,
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_TEXT_CONTAINS],
      defaultFilterMethod: METHOD_TEXT_CONTAINS,
      searchPlaceholder: "Search by Flagged Aliquot",
    }),
  },
  {
    title: "Lab Notes",
    width: 280,
    dataIndex: "notes",
    ...columnSearchProps({ placeholder: "Search by Notes" }),
  },
  {
    title: "Protocol Version",
    dataIndex: "lab_protocol_version",
    render: renderVersion,
  },
  {
    title: "Standard Curve Version",
    dataIndex: "standard_curve_version",
    render: renderVersion,
  },
  {
    title: "Upload Schema Version",
    dataIndex: "upload_schema_version",
    render: renderVersion,
  },
  {
    title: "File Upload Timestamp",
    dataIndex: "created_at",
    sorter: true,
    render: (record) => (record ? toLocaleString(record) : ""),
    ...strapiFilterProps({
      enabledFilterMethods: [METHOD_DAY_RANGE_EXCLUSIVE_END],
    }),
  },
];
