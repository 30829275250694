import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  Row,
  Col,
  Form,
  PageHeader,
  Typography,
  Radio,
  Button,
  message,
} from "antd";

import Loader from "components/Loader/Loader";
import SearchOrg from "components/SearchOrg";
import ConfirmOrg from "components/ConfirmOrg";
import ConfirmationErrorMessage from "components/ConfirmationErrorMessage";
import Description from "./components/Description";
import OrganizationQuestions from "./components/OrganizationQuestions";
import SamplingLocations from "./components/SamplingLocations";
import ProgramAgreements from "./components/ProgramAgreements";
import DataSharingAgreements from "./components/DataSharingAgreements";
import DataVizInclusion from "./components/DataVizInclusion";
import AdditionalContacts from "./components/AdditionalContacts";
import { convertFormValuesToLeadPayload } from "../helpers";
import "./styles.css";
import { fixtures } from "../fixtures";
import { useSignupContext } from "context/SignUpContext";
import { programsCompatibleWithViz } from "configs/constants";
import MaintenanceWarningBanner from "components/PageWarningBanners/MaintenanceWarningBanner";

const { Title, Text } = Typography;

export default function Start() {
  const params = new URLSearchParams(window.location.search);
  const orgIdFromQueryString = params.get("org-id"); //optionally prefill org id

  const history = useHistory();
  const { variant } = useSignupContext();
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState();
  const [formError, setFormError] = useState(false);
  const [isReturningCustomer, setIsReturningCustomer] = useState(
    orgIdFromQueryString && true
  );
  const [organization, setOrganization] = useState();
  const [orgConfirmed, setOrgConfirmed] = useState();
  const [agreesToTerms, setAgreesToTerms] = useState(true);
  const formFixtures = fixtures[variant];

  const [form] = Form.useForm();

  const agreementMessage =
    "Please indicate you agree in order to submit this form";

  const canCustomersShareResultsWithViz = programsCompatibleWithViz.includes(
    formFixtures.program
  );

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, isReturningCustomer, orgConfirmed]);

  const handleOrgSearch = (org) => {
    if (org?.id !== organization?.id) {
      setOrgConfirmed(null);
      setOrganization(org);
    }
  };

  const handleConfirmationChanged = (isConfirmed) => {
    if (orgConfirmed !== isConfirmed) {
      setOrgConfirmed(isConfirmed);
    }
  };

  const handleReturningCustomer = (event) => {
    const checked = event.target.value;
    setIsReturningCustomer(checked);
    setOrgConfirmed(null);
    setOrganization(null);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API}/identity/userTokens/types/SITE_METADATA` //TODO: configure SIGN_UP token
      )
      .then((response) => {
        if (response.status === 201) {
          const t = response.data.accessToken;
          setToken(t);
        }
      })
      .catch((e) => setApiError(e))
      .then(() => setLoading(false));
  }, []);

  const handleSubmit = (values) => {
    setFormError(false);
    setLoading(true);
    const payload = convertFormValuesToLeadPayload(
      values,
      organization,
      formFixtures.program
    );
    axios
      .post(
        `${process.env.REACT_APP_API_URL}leads`,
        {
          ...payload,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => history.push(`${formFixtures.basePath}/confirm`))
      .catch((e) => {
        if (e?.response?.status === 400 || e?.response?.status === 401) {
          message.error(
            "Your session has expired. Please refresh the page in order to enter the information required to submit this form.",
            5
          );
        } else {
          history.push(`${formFixtures.basePath}/failed`);
        }
      })
      .finally(() => setLoading(false));
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    setFormError(true);
    if (errorFields.some((x) => x?.name[0]?.includes("participant"))) {
      setAgreesToTerms(false);
    } else {
      setAgreesToTerms(true);
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (apiError) {
    return <Text>Something went wrong!</Text>;
  }

  return (
    <>
      {process.env.REACT_APP_CUSTOMER_MAINTENANCE_BANNER === "true" && (
        <MaintenanceWarningBanner />
      )}
      <Row className="sign-up">
        <Helmet htmlAttributes={{ lang: "en" }}>
          <title>{formFixtures.startTitleText}</title>
        </Helmet>
        <Col>
          <Row>
            <Col span={24}>
              <PageHeader
                title={formFixtures.startTitleText}
                className="pl-0"
              />
            </Col>
          </Row>

          <Description />

          {!formFixtures.isFormForExistingCustomers ? (
            <>
              <Row>
                <Title level={3}>
                  Have you worked with Biobot in the past?
                </Title>
              </Row>
              <Row className="mb-4">
                <Radio.Group
                  onChange={(e) => handleReturningCustomer(e)}
                  defaultValue={!!orgIdFromQueryString && true}
                  value={isReturningCustomer}
                >
                  <Radio value={true} className="full-width">
                    Yes
                  </Radio>
                  <Radio value={false} className="full-width">
                    No
                  </Radio>
                </Radio.Group>
              </Row>
            </>
          ) : null}
          <Col span={24} className="mb-2">
            {token &&
              (isReturningCustomer ||
                formFixtures.isFormForExistingCustomers) && (
                <SearchOrg
                  token={token}
                  onOrgFound={(org) => handleOrgSearch(org)}
                  onOrgNotFound={(org) => handleOrgSearch(null)}
                />
              )}

            {organization &&
              (isReturningCustomer ||
                formFixtures.isFormForExistingCustomers) && (
                <ConfirmOrg
                  organization={organization}
                  onConfirmationChanged={handleConfirmationChanged}
                />
              )}

            {orgConfirmed === false && <ConfirmationErrorMessage />}

            {(isReturningCustomer === false || orgConfirmed) && (
              <Form
                form={form}
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}
                layout={"vertical"}
              >
                <OrganizationQuestions
                  form={form}
                  organization={organization}
                />
                <SamplingLocations form={form} organization={organization} />

                {formFixtures.programAgreements?.length && (
                  <ProgramAgreements agreementMessage={agreementMessage} />
                )}

                {formFixtures.dataSharingAgreements?.length && (
                  <DataSharingAgreements />
                )}

                {!agreesToTerms && (
                  <Typography.Paragraph
                    type="danger"
                    data-cy="agree-terms-alert"
                  >
                    Please indicate that you agree to all required terms of the
                    program in order to submit this form
                  </Typography.Paragraph>
                )}

                {canCustomersShareResultsWithViz && <DataVizInclusion />}

                {!formFixtures.isFormForExistingCustomers && (
                  <AdditionalContacts />
                )}

                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  className="mt-2"
                >
                  Submit
                </Button>
                {formError && (
                  <Row>
                    <Text type="danger" data-cy="unable-submit-alert">
                      Unable to submit form, please see errors above
                    </Text>
                  </Row>
                )}
              </Form>
            )}
          </Col>
        </Col>
      </Row>
    </>
  );
}
