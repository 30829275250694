import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Form,
  message,
  Modal,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

import { VersionSelector, UploadResultTable } from "utils/components";
import SingleBatchFormFields from "./SingleBatchFormFields";
import {
  PCR_UPLOAD_ASSAY_OPTIONS,
  PCR_METHOD_TYPE_OPTIONS,
  useBulkCreatePcrUpload,
  usePcrLabProtocolVersionList,
  usePcrUploadSchemaVersionList,
  useStandardCurveVersionList,
  DDPCR_UPLOAD_ASSAY_OPTIONS,
} from "api/fileUpload";
import {
  COVID_ASSAY_PROD,
  QPCR_METHOD_TYPE,
  DDPCR_METHOD_TYPE,
  RESP_ASSAY_PROD,
} from "configs/constants";

const PcrUploadFormPage = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadResults, setUploadResults] = useState();
  const [isResultsModalOpen, setIsResultsModalOpen] = useState(false);

  const { mutateAsync: bulkCreatePcrUploads } = useBulkCreatePcrUpload();
  const { data: labProtocolVersions } = usePcrLabProtocolVersionList();
  const { data: standardCurveVersions } = useStandardCurveVersionList();
  const { data: pcrUploadSchemaVersions } = usePcrUploadSchemaVersionList();

  const [pcrType, setPcrType] = useState(QPCR_METHOD_TYPE);

  const handleFormSubmit = async (formValue) => {
    setIsLoading(true);
    message.loading("Uploading PCR...", 0);
    const results = await bulkCreatePcrUploads({
      ...formValue,
      lab_method: pcrType,
    });
    setUploadResults(results);
    message.destroy();
    setIsResultsModalOpen(true);
    setIsLoading(false);
  };

  const handleFileUpload = async (file, fieldPath) => {
    const fieldVal = form.getFieldValue(fieldPath) || {};
    fieldVal["uploaded_file"] = file;
    form.setFieldValue(fieldPath, fieldVal);
  };

  const handleModalAction = () => {
    setIsResultsModalOpen(false);
    setUploadResults(false);
  };

  const handleFormValuesChange = (val) => {
    if (val?.qpcr_type) {
      setPcrType(val.qpcr_type);
      form.setFieldsValue({
        assay_name:
          val.qpcr_type !== DDPCR_METHOD_TYPE
            ? COVID_ASSAY_PROD
            : RESP_ASSAY_PROD,
      });
    }
  };

  return (
    <>
      <PageHeader title="PCR Upload Submission" />
      <Form
        name="qpcr-upload"
        layout="vertical"
        autoComplete="off"
        className="px-3 py-4"
        form={form}
        onValuesChange={handleFormValuesChange}
        onFinish={handleFormSubmit}
      >
        {/* Submitted By */}
        <Row>
          <Col span={10}>
            <Form.Item name="form_submitted_by" label="Submitted By" required>
              <Input placeholder="Initials" />
            </Form.Item>
          </Col>
        </Row>

        {/* PCR Type */}
        <Row>
          <Col span={10}>
            <Form.Item
              name="qpcr_type"
              label="PCR Type"
              required
              initialValue={pcrType}
            >
              <Select options={PCR_METHOD_TYPE_OPTIONS} />
            </Form.Item>
          </Col>
        </Row>

        {/* Assay Name */}
        <Row>
          <Col span={10}>
            <Form.Item
              name="assay_name"
              label="Assay Name"
              required
              initialValue={COVID_ASSAY_PROD}
            >
              <Select
                options={
                  pcrType !== DDPCR_METHOD_TYPE
                    ? PCR_UPLOAD_ASSAY_OPTIONS
                    : DDPCR_UPLOAD_ASSAY_OPTIONS
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={20}>
          {/* PCR Ran By */}
          <Col span={5}>
            <Form.Item name="qpcr_ran_by" label="Person who ran PCR">
              <Input placeholder="Initials" />
            </Form.Item>
          </Col>

          {/* Date of PCR */}
          <Col span={5}>
            <Form.Item name="date_of_qpcr" label="Date of PCR">
              <DatePicker
                className="w-100"
                format={(date) => date.utc().format()}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Lab Protocol Version */}
        <Row>
          <Col span={10}>
            <VersionSelector
              name="lab_protocol_version"
              label="Lab Protocol Version"
              versionNumberPrefix="-v"
              groupByAssay={true}
              versions={labProtocolVersions}
            />
          </Col>
        </Row>

        {/* Upload Schema Version */}
        <Row>
          <Col span={10}>
            <VersionSelector
              name="upload_schema_version"
              label="What format is the data in this file in?"
              versionNumberPrefix="v"
              versions={pcrUploadSchemaVersions}
            />
          </Col>
        </Row>

        {/* Standard Curve Version */}
        {pcrType !== DDPCR_METHOD_TYPE ? (
          <Row>
            <Col span={10}>
              <VersionSelector
                name="standard_curve_version"
                label="Standard Curve Version"
                versionNumberPrefix="-v"
                versions={standardCurveVersions}
                groupByAssay={true}
              />
            </Col>
          </Row>
        ) : null}

        {/* Upload List */}
        <Form.List name="batches" initialValue={[{}]}>
          {(fields, { add: addBatch, remove: removeBatch }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <SingleBatchFormFields
                  key={key}
                  acceptTypes={pcrType !== DDPCR_METHOD_TYPE ? ".xlsx" : ".csv"}
                  batchFieldIdx={name}
                  assayName={form.getFieldValue("assay_name")}
                  onRemove={() => removeBatch(name)}
                  onUpload={handleFileUpload}
                  {...restField}
                />
              ))}
              <Form.Item style={{ marginLeft: "auto" }}>
                <Button
                  type="dashed"
                  icon={<PlusOutlined />}
                  onClick={() => addBatch()}
                >
                  Add Batch
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        <Divider className="mt-5" />
        <Space align="center" className="flex justify-center">
          <Popconfirm
            title="Reset Form?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => form.resetFields()}
          >
            <Button disabled={isLoading}>Reset</Button>
          </Popconfirm>
          <Form.Item className="mb-0">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>

      <Modal
        title="PCR Upload Results"
        open={isResultsModalOpen}
        onOk={handleModalAction}
        onCancel={handleModalAction}
      >
        <UploadResultTable uploadResults={uploadResults} />
      </Modal>
    </>
  );
};

export default PcrUploadFormPage;
