import React, { useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import fileDownload from "js-file-download";
import { PageHeader, Table, Modal, Row, message, Button, Tooltip } from "antd";
import { DownloadOutlined, ExclamationCircleTwoTone } from "@ant-design/icons";
import { exportKits, useKitCount, useKitList } from "api/kit";
import { useProgramList } from "api/program";

import { createKitTableColumns } from "./helpers";
import { useTableQueryParams } from "components/Table/helpers";

import SampleLanding from "containers/Sample/Landing";
import {
  useFormReviewStatusList,
  useFormReviewStatusOptions,
} from "api/formReviewStatus";

import "./Kits.css";

const maxDownload = 10000;

const KitsPage = ({
  defaultDateReceivedFilterMethod,
  defaultDateReceivedFilterValue,
  pageTitle = null,
} = {}) => {
  const [modalContent, setModalContent] = useState();
  const [modalOpen, setModalOpen] = useState();

  const { data: programs } = useProgramList();

  // Set search params for kits, organizations, sampling locations, review statuses
  const { queryParams, pagination, handleTableChange } = useTableQueryParams({
    defaultPageSize: 50,
    defaultSort: "date_received:DESC",
    defaultFilters: {
      date_received: [
        {
          method: defaultDateReceivedFilterMethod,
          value: defaultDateReceivedFilterValue,
        },
      ],
    },
  });

  // fetch data for kits, organizations, sampling locations, review statuses
  const allKits = useKitList({
    ...queryParams,
    _joins: [
      "configuration",
      "products.assay",
      "organization",
      "sample",
      "sample.author",
      "order.shipping_location",
      "sample.sampling_location_id.program_enrollment.program",
      "selection_statuses.program",
    ],
  });
  const { data: allKitsCount } = useKitCount(queryParams);
  const { data: reviewStatusFilterOptions } = useFormReviewStatusOptions();
  const { data: reviewStatuses } = useFormReviewStatusList();

  const handleDownloadCSV = async () => {
    const date = moment().format("MM-DD-YYYY");
    return exportKits({
      ...queryParams,
      _limit: maxDownload,
      as_csv: {
        fields: columns
          .map(
            ({ title, key, csvConfig }) =>
              csvConfig ?? {
                label: title,
                value: key,
              }
          )
          .filter(Boolean),
      },
    })
      .then((response) => fileDownload(response, `${date}-kits.csv`))
      .catch((err) => message.error("Something went wrong!", err));
  };

  const handleLogSampleClicked = (kitRow) => {
    setModalContent(
      <SampleLanding
        kitId={kitRow.id}
        onFormSubmissionSuccess={() => allKits.refetch()}
      />
    );
    setModalOpen(true);
  };

  const columns = createKitTableColumns(
    reviewStatusFilterOptions,
    handleLogSampleClicked,
    reviewStatuses,
    programs,
    defaultDateReceivedFilterMethod,
    defaultDateReceivedFilterValue
  );

  return (
    <div className="admin-kits-page" style={{ height: "100%" }}>
      <PageHeader title={pageTitle ?? "Kits"} />
      <Row className="ml-3">
        Total Kits Received: {allKitsCount ?? 0}
        <Button
          onClick={handleDownloadCSV}
          className="ml-1 mb-1"
          type="primary"
          size="small"
          shape="round"
        >
          <DownloadOutlined />
          {allKitsCount > maxDownload ? (
            <Tooltip
              placement="right"
              fill="red"
              title={`More than ${maxDownload} kits requested for download. CSV results will be truncated.`}
            >
              <ExclamationCircleTwoTone twoToneColor="#eb2f96" />
            </Tooltip>
          ) : null}
        </Button>
      </Row>
      <Table
        className="admin-kits-table ml-3"
        data-cy="kits-received-table"
        rowKey="id"
        loading={allKits.isLoading}
        columns={columns}
        pagination={{ ...pagination, total: allKitsCount }}
        dataSource={allKits.data}
        onChange={handleTableChange}
        scroll={{ x: "min-content" }}
      />
      <Modal
        className="kits-modal"
        visible={modalOpen}
        width="75%"
        footer={[]}
        onCancel={() => setModalOpen(false)}
        destroyOnClose
      >
        <div className="sample-modal-content">{modalContent}</div>
      </Modal>
    </div>
  );
};

export default withRouter(KitsPage);
