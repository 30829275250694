import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import { authorizationHeader, pipelineAPIRoot } from "../util";
import { ManualReviewState } from "../../reducers/reducerManualReview";
import { ManualReviewStatus } from "./types";

interface OverrideSubmission {
  aliquot_id: number;
  aliquot_name: string;
  status_override: ManualReviewStatus;
  notes: string | null;
}

export const submitReview = (
  state: ManualReviewState,
  overrides: OverrideSubmission[]
): AxiosPromise<any> => {
  const config: AxiosRequestConfig = {
    method: "POST",
    url: `${pipelineAPIRoot}/reviews/`,
    headers: authorizationHeader(),
    data: {
      assay_name: state.activeAssayName,
      overrides: overrides,
    },
  };

  return axios(config);
};
