import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useUIContext } from "context";

import {
  actionOrganizationGet,
  actionOrderReset,
  setSiderCollapsed,
} from "actions";
import OrgImpersonationSelect from "./components/OrgImpersonationSelect";
import AdminToggleButton from "./components/AdminToggleButton";
import NavOverflowMenu from "./components/NavOverflowMenu";
import "./logo.png";
import { getToken } from "api/util";

const mapStateToProps = ({ ui }) => ({
  ui,
});

const mapDispatchToProps = {
  actionOrganizationGet,
  actionOrderReset,
  setSiderCollapsed,
};

function Nav({
  isPublic,
  ui: { siderCollapsed: isSiderCollapsed },
  history,
  actionOrganizationGet,
  actionOrderReset,
  setSiderCollapsed,
}) {
  const token = getToken();
  const uiContext = useUIContext();
  const { showAdmin, updateView } = uiContext;

  const buttonText = showAdmin ? "View Customer" : "View Admin";
  function toggleView() {
    updateView(!showAdmin, history);
  }

  function toggleSider() {
    setSiderCollapsed(!isSiderCollapsed);
  }

  return (
    <Row justify="space-between" className="full-width" wrap={false}>
      <Col flex="auto">
        <div className="col__nav-logo-container mt-0">
          <img
            src="https://bbpubdataviz.s3-us-west-2.amazonaws.com/portal_assets/darkbluelogo_large.png"
            style={{ width: "12rem" }}
            alt="Biobot Analytics Logo"
          />
        </div>
        {!isPublic &&
          React.createElement(
            isSiderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "mobile-menu__btn",
              onClick: () => toggleSider(),
            }
          )}
      </Col>
      <Col flex="auto">
        <Row justify="end" gutter={16} wrap={false}>
          <Col flex="auto" style={{ maxWidth: "16rem" }}>
            {!showAdmin && !isPublic && (
              <OrgImpersonationSelect
                actionOrderReset={actionOrderReset}
                actionOrganizationGet={actionOrganizationGet}
              />
            )}
          </Col>
          <Col>
            {token && (
              <AdminToggleButton
                buttonText={buttonText}
                toggleView={toggleView}
              />
            )}
          </Col>
          <Col>{token && <NavOverflowMenu history={history} />}</Col>
        </Row>
      </Col>
    </Row>
  );
}

const NavContainer = connect(mapStateToProps, mapDispatchToProps)(Nav);

export default withRouter(NavContainer);
