import { useEffect, useState } from "react";
import axios from "axios";
import { Button, Col, Form, PageHeader, Row, Typography, message } from "antd";
import { Helmet } from "react-helmet";
import SubmissionResult from "components/SubmissionResult";
import SearchOrg from "components/SearchOrg";
import ConfirmOrg from "components/ConfirmOrg";
import Loader from "components/Loader/Loader";
import SelectLocation from "components/SelectLocation";
import ConfirmationErrorMessage from "components/ConfirmationErrorMessage";
import SelectLocationType from "./components/SelectLocationType";
import CatchmentOverview from "./components/CatchmentOverview";
import SamplingDetails from "./components/SamplingDetails";
import SiteDetails from "./components/SiteDetails";
import Signature from "./components/Signature";
import LocationName from "./form/LocationName";
import FacilityOverview from "./components/FacilityOverview";
import _ from "lodash";
import PageError from "./components/PageError";
import MaintenanceWarningBanner from "components/PageWarningBanners/MaintenanceWarningBanner";
import { SampleMatrixForLocation } from "containers/Sample/components/SampleMatrix";

const { Link, Paragraph, Text } = Typography;

const PageLayout = ({ children, shouldHideSupportInfo }) => (
  <Row className="max-960px mt-5 mx-2">
    <Col xs={{ offset: 1, span: 22 }} sm={{ offset: 2, span: 20 }}>
      <PageHeader title={<h1>Sampling Location Details</h1>} className="px-0">
        {!shouldHideSupportInfo && (
          <Paragraph>
            The purpose of this form is for you to submit unique information for{" "}
            <Text strong>each</Text> of your sampling locations. If you
            experience any issues submitting your sampling data, please contact
            us at <Link href="mailto:support@biobot.io">support@biobot.io</Link>
            .
          </Paragraph>
        )}
      </PageHeader>
      {children}
    </Col>
  </Row>
);

const SamplingLocation = ({
  internalOrg,
  internalSamplingLoc,
  isInternal = false,
  callback = () => {},
}) => {
  const [token, setToken] = useState();
  const [loading, setLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [states, setStates] = useState();
  const [npdesIds, setNpdesIds] = useState();
  const [samplingSiteTypes, setSamplingSiteTypes] = useState();
  const [facilityTypes, setFacilityTypes] = useState();
  const [organization, setOrganization] = useState(internalOrg || null);
  const [orgConfirmed, setOrgConfirmed] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(
    internalSamplingLoc || null
  );
  const [locationType, setLocationType] = useState(
    internalSamplingLoc?.location_type
  );

  const [form] = Form.useForm();

  const getAxiosParams = (token) => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  };

  const getStates = (token) => {
    return axios
      .get(
        `${process.env.REACT_APP_DATASETS_API_URL}/datasets/master_data/states`,
        getAxiosParams(token)
      )
      .then(({ data }) => {
        setStates(data);
      });
  };

  const getNpdesIds = () => {
    return axios
      .get(`${process.env.REACT_APP_BIOBOT_ASSETS_BUCKET}/npdes.json`)
      .then(({ data }) => {
        setNpdesIds(data.ids);
      });
  };

  const getSamplingSiteType = (token) => {
    return axios
      .get(
        `${process.env.REACT_APP_DATASETS_API_URL}/datasets/other_data/sampling_site_type`,
        getAxiosParams(token)
      )
      .then(({ data }) => {
        setSamplingSiteTypes(data);
      });
  };

  const getFacilityTypes = (token) => {
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}facility-types`,
        getAxiosParams(token)
      )
      .then(({ data }) => {
        setFacilityTypes(data);
      });
  };

  const createSamplingLocation = (token, body) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}sampling-locations/`,
        body,
        getAxiosParams(token)
      )
      .then(({ data }) => {
        setCompleted(true);
      });
  };

  const updateSamplingLocation = (token, body) => {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}sampling-locations/${
          selectedLocation.id
        }${isInternal ? "?email=false" : ""}`,
        body,
        getAxiosParams(token)
      )
      .then(({ data }) => {
        setCompleted(true);
        if (isInternal) {
          setSelectedLocation(data);
          callback(data); // Need this callback to trigger re-render of parent component when called from a modal
        }
      });
  };

  useEffect(() => {
    setLoading(true);

    axios
      .post(
        `${process.env.REACT_APP_API}/identity/userTokens/types/SITE_METADATA`
      )
      .then(async (response) => {
        const t = response.data.accessToken;
        setToken(t);

        const promises = [
          getStates(t),
          getNpdesIds(),
          getSamplingSiteType(t),
          getFacilityTypes(t),
        ];
        await Promise.all(promises);
      })
      .catch((e) => setPageError(e))
      .then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, locationType]);

  const handleOrgSearch = (org) => {
    if (org?.id !== organization?.id) {
      setLocationType(null);
      setSelectedLocation(null);
      setOrgConfirmed(null);
      setOrganization(org);
    }
  };

  const handleConfirmationChanged = (isConfirmed) => {
    if (orgConfirmed !== isConfirmed) {
      setLocationType(null);
      setSelectedLocation(null);
      setOrgConfirmed(isConfirmed);
    }
  };

  const handleLocationSelected = (location) => {
    if (location === "new_site" || selectedLocation?.id !== location?.id) {
      setLocationType(location?.location_type);
      setSelectedLocation(location);
    }
  };

  const handleSubmit = (data) => {
    setLoading(true);
    setValidationError(false);

    let body = _.set(data, "organization", organization.id);
    body = _.set(body, "location_type", locationType);

    // Handling an edge case where if a facility that was formerly a community location has it's state changed
    // the county fields need to be cleared. This happens automatically when a community location has it's state
    // changed, but the county fields are not present on the facility version of the form.
    if (locationType === "facility" && form.isFieldTouched("state_id")) {
      body.county_id = null;
      body.counties_served = null;
    }

    if (_.isNil(body.counties_served)) {
      body.counties_served = null;
    }

    if (body.counties_served) {
      body.counties_served = body.counties_served.filter(
        (c) => !_.isNil(c) && c !== ""
      );
    }

    if (selectedLocation === "new_site") {
      createSamplingLocation(token, body)
        .catch((e) => {
          if (e?.response?.status === 400 || e?.response?.status === 401) {
            message.error(
              "Your session has expired. Please refresh the page in order to enter the information required to submit this form.",
              5
            );
          } else {
            message.error("Something went wrong!");
          }
        })
        .then(() => setLoading(false));
    } else {
      updateSamplingLocation(token, body)
        .catch(() => message.error("Something went wrong!"))
        .then(() => setLoading(false));
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (pageError) {
    return (
      <PageLayout>
        <PageError />
      </PageLayout>
    );
  }

  if (submitError) {
    return (
      <PageLayout>
        <Helmet
          defaultTitle={"Biobot Analytics Sampling Location Details - Error"}
          htmlAttributes={{ lang: "en" }}
        >
          <title>
            Biobot Analytics Sampling Location Details. Form Submission Error.
          </title>
        </Helmet>
        <SubmissionResult status="error" />
      </PageLayout>
    );
  }

  if (completed) {
    return (
      <PageLayout>
        <Helmet
          defaultTitle={"Biobot Analytics Sampling Location Details - Success"}
          htmlAttributes={{ lang: "en" }}
        >
          <title>
            Biobot Analytics Sampling Location Details. Form Submitted
            Successfully.
          </title>
        </Helmet>
        <SubmissionResult status="success" />
      </PageLayout>
    );
  }

  return (
    <>
      {process.env.REACT_APP_CUSTOMER_MAINTENANCE_BANNER === "true" &&
        !isInternal && <MaintenanceWarningBanner />}
      <PageLayout shouldHideSupportInfo={isInternal}>
        <Helmet
          htmlAttributes={{ land: "en" }}
          defaultTitle={"Biobot Analytics Sampling Location Details"}
        />
        {token && !isInternal && (
          <SearchOrg
            token={token}
            onOrgFound={(org) => handleOrgSearch(org)}
            onOrgNotFound={() => handleOrgSearch(null)}
          />
        )}

        {organization && !isInternal && (
          <ConfirmOrg
            organization={organization}
            onConfirmationChanged={handleConfirmationChanged}
          />
        )}

        {orgConfirmed === true && !isInternal && (
          <SelectLocation
            locations={organization?.sampling_locations}
            onLocationSelected={handleLocationSelected}
          />
        )}

        {orgConfirmed === false && <ConfirmationErrorMessage />}

        {selectedLocation && (
          <SelectLocationType
            location={selectedLocation}
            onLocationTypeChanged={(type) => setLocationType(type)}
          />
        )}

        {locationType && (
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              email: isInternal ? selectedLocation?.author_id?.email : null,
              first_name: isInternal
                ? selectedLocation?.author_id?.first_name
                : null,
              last_name: isInternal
                ? selectedLocation?.author_id?.last_name
                : null,
            }}
            onFinish={handleSubmit}
            onFinishFailed={() => setValidationError(true)}
            onValuesChange={() => setValidationError(false)}
          >
            {selectedLocation === "new_site" && <LocationName />}

            {locationType === "community" && (
              <CatchmentOverview
                organization={organization}
                location={selectedLocation}
                states={states}
                npdesIds={npdesIds}
                token={token}
                onError={(e) => setSubmitError(e)}
                form={form}
                isInternal={isInternal}
              />
            )}

            {locationType === "facility" && (
              <FacilityOverview
                organization={organization}
                location={selectedLocation}
                states={states}
                facilityTypes={facilityTypes}
                form={form}
                isInternal={isInternal}
                onError={(e) => setSubmitError(e)}
                token={token}
              />
            )}

            <SiteDetails
              location={selectedLocation}
              samplingSiteTypes={samplingSiteTypes}
              locationType={locationType}
              form={form}
              isInternal={isInternal}
            />

            {locationType === "community" && (
              <>
                <SampleMatrixForLocation
                  isRequired={true}
                  location={selectedLocation}
                />
                <SamplingDetails
                  location={selectedLocation}
                  isInternal={isInternal}
                />
              </>
            )}

            <Signature isInternal={isInternal} />
            <Button type="primary" htmlType="submit" disabled={loading}>
              {isInternal ? "Submit - this will not send an email" : "Submit"}
            </Button>
            {validationError && (
              <Text type="danger" className="pl-2">
                Please correct form errors.
              </Text>
            )}
          </Form>
        )}
      </PageLayout>
    </>
  );
};

export default SamplingLocation;
