// external
import React, { useState } from "react";
import { Modal } from "antd";
import _ from "lodash";

// components
import Loader from "components/Loader/Loader";
import ReviewStatusForm from "components/ReviewStatusForm/ReviewStatusForm";
import SampleLanding from "containers/Sample/Landing";
import KitDetails from "./components/KitDetails";
import SampleLogDetails from "./components/SampleLogDetails";
import NotFound from "../../NotFound/NotFound";

// api
import { useUpdateSample } from "api/sample";
import { useKit } from "../../../api/kit";

// css
import "./index.css";

function Index({ match: { params } }) {
  const [kitId] = useState(params.id);
  const [modalOpen, setModalOpen] = useState();
  const [modalContent, setModalContent] = useState();

  const { data: kit, isLoading, isError, refetch: refetchKitDetails } = useKit({
    id: kitId,

    _joins: [
      "configuration",
      "order.shipping_location",
      "products",
      "sample.author",
      "sample.review_status",
      "sample.sampling_location_id",
      "selection_statuses.program",
    ],
  });
  const { mutate: updateSample } = useUpdateSample();

  if (isError) {
    return <NotFound />;
  } else if (isLoading) {
    return <Loader />;
  }

  const handleLogEditClicked = () => {
    setModalContent(
      <SampleLanding
        kitId={kit.id}
        isInternalUser={true}
        onFormSubmissionSuccess={handleSampleSubmitted}
      />
    );
    setModalOpen(true);
  };

  const handleSampleSubmitted = () => {
    setModalOpen(false);
    refetchKitDetails();
  };

  const handleReviewStatusUpdate = (reviewedSample, onSuccess, onError) => {
    const sampleIds = _.pick(kit.sample, ["id"]);
    const updatedSample = { ...sampleIds, ...reviewedSample };
    updateSample(updatedSample, { onSuccess, onError });
  };

  return (
    <div>
      <Modal
        className="kits-modal"
        visible={modalOpen}
        width={"75%"}
        footer={[]}
        onCancel={() => setModalOpen(false)}
        destroyOnClose
      >
        <div className="kit-modal-content">{modalContent}</div>
      </Modal>
      <KitDetails kit={kit} />
      <SampleLogDetails
        kit={kit}
        refetchKitDetails={refetchKitDetails}
        onSampleLogButtonClicked={handleLogEditClicked}
      />
      {kit?.sample && (
        <ReviewStatusForm
          reviewedByProp="log_reviewed_by"
          item={{ date_received: kit.date_received, ...kit?.sample }}
          onFinishCallback={handleReviewStatusUpdate}
        />
      )}
    </div>
  );
}

export default Index;
