import { Input, Form, Row, Col, Typography } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";

import StatesSelector from "components/States";
import ZipCode from "../form/ZipCode";
import FacilityTypeSelector from "./FacilityTypeSelector";
import FacilityTypeSpecify from "../form/FacilityTypeSpecify";
import PrimaryCountySelector from "../form/PrimaryCountySelector";

const FacilityOverview = ({
  organization,
  location,
  states,
  facilityTypes,
  form,
  isInternal,
  token,
  onError,
}) => {
  const [isFacilityTypeOther, setIsFacilityTypeOther] = useState();

  const [stateId, setStateId] = useState();
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    setStateId(location.state_id);
  }, [location]);

  useEffect(() => {
    if (stateId) {
      axios
        .get(
          `${process.env.REACT_APP_DATASETS_API_URL}/datasets/master_data/counties?state_id=${stateId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(({ data }) => {
          setCounties(data);
        })
        .catch((e) => onError(e));
    }
  }, [token, stateId, onError]);

  const handleStateSelected = (value) => {
    setStateId(value);
    form.setFieldsValue({ county_id: null });
    form.setFieldsValue({ counties_served: null });
  };

  useEffect(() => {
    setIsFacilityTypeOther(location.facility_type_specify);
  }, [location]);

  const handleFacilityTypeSelected = (value) => {
    if (value === 7) {
      setIsFacilityTypeOther(true);
    } else {
      setIsFacilityTypeOther(false);
      form.setFieldsValue({ facility_type_specify: null });
    }
  };

  return (
    <>
      <Row gutter={[48, 16]} className="mb-4">
        <Col span={24}>
          <Typography.Title level={4}>
            Overview for {organization.organization_name}
          </Typography.Title>
          <Typography.Text>
            In order to complete your report we need information about your
            sampling location. Please answer the required questions below.
          </Typography.Text>
        </Col>
      </Row>
      <Row gutter={[48, 16]} className="mb-4">
        <Col sm={8} xs={24}>
          <StatesSelector
            label={organization.country === "Lithuania" ? "County" : "State"}
            name="state_id"
            initialValue={location.state_id}
            required={true}
            states={states}
            onSelect={handleStateSelected}
          />
        </Col>
        <Col sm={8} xs={24}>
          <Form.Item
            name="city"
            label="City"
            initialValue={location.city}
            className="mb-0"
          >
            <Input placeholder="Specify city name" />
          </Form.Item>
        </Col>
        <Col sm={8} xs={24}>
          <ZipCode initialValue={location.zip_id} isInternal={isInternal} />
        </Col>
      </Row>
      <Row gutter={[48, 16]} className="mb-4">
        <Col md={16} sm={24} xs={24}>
          <PrimaryCountySelector
            counties={counties}
            isInternal={isInternal}
            initialValue={location.county_id}
          />
        </Col>
      </Row>
      <Row gutter={[48, 16]} className="mb-4">
        <Col sm={16} xs={24}>
          <FacilityTypeSelector
            facilityTypes={facilityTypes}
            initialValue={location.facility_type}
            onSelect={handleFacilityTypeSelected}
          />
        </Col>
        <Col sm={8} xs={24}>
          <FacilityTypeSpecify
            initialValue={location.facility_type_specify}
            disabled={isFacilityTypeOther !== true}
          />
        </Col>
      </Row>
    </>
  );
};

export default FacilityOverview;
