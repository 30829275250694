import { message } from "antd";

export const placeOrder = ({
  order,
  organizationId,
  setLoading,
  history,
  actionOrderAdd,
  values,
}) => {
  setLoading(true);

  let isOrderShippingValid = getShippingLocations(order).length > 0;
  getShippingLocations(order).forEach((location) => {
    if (!location.id) {
      isOrderShippingValid = false;
    }
  });
  if (isOrderShippingValid === false) {
    message.error("There was an error placing your order. Please try again.");
    setLoading(false);
    return;
  }

  const thisOrder = {
    organization: organizationId,
    shipping_locations: getShippingLocations(order),
    product: order.sku,
    number_of_cycles: order.cycles,
    confirmation_email: values["confirmation_email"],
    requires_purchase_order: values["requires_purchase_order"],
    requested_shipping_date: order["requested-shipping-date"],
    shipping_speed: order["shipping-speed"],
    sampling_schedule: values["sampling_schedule"],
    send_confirmation_email: values["send_emails"].includes(
      "order_confirmation"
    ),
    send_shipping_update_email: values["send_emails"].includes(
      "shipping_updates"
    ),
    kit_configuration: values.kit_configuration,
    kit_provider: values.kit_provider,
    fulfillment_priority: values.fulfillment_priority.toLowerCase(),
  };
  actionOrderAdd(thisOrder)
    .then((res) => {
      history.push(`/order/${res.payload.data.orders[0].id}`);
      message.success("Your Order was placed.");
      setLoading(false);
    })
    .catch((err) => {
      message.error("There was an error placing your order.");
      setLoading(false);
    });
};

export const getShippingLocations = (order) => {
  const array = [];
  order["shipping-locations"].forEach((item, index) => {
    array.push({
      id: order[`location-${index + 1}-shipping-address`],
      kits: order[`location-${index + 1}-number-of-kits`],
    });
  });
  return array;
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
