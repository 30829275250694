import { useEffect, useState } from "react";
import { Modal, Typography, Button, Alert } from "antd";
import _ from "lodash";
import { AlertOutlined } from "@ant-design/icons";
import { missingAny } from "utils/objects";

import Loader from "components/Loader/Loader";
import MetadataDetailTable from "components/MetadataDetailTable/MetadataDetailTable";
import SamplingLocationForm from "containers/SamplingLocation/publicIndex";
import ReviewStatusForm from "components/ReviewStatusForm/ReviewStatusForm";

import ProgramEnrollmentTable from "./ProgramEnrollmentTable";

import {
  useSamplingLocation,
  useUpdateSamplingLocation,
} from "api/samplingLocation";
import { useOrganization } from "api/organization";
import { useStateList } from "api/states";
import { useCountyList } from "api/counties";

function SamplingLocation({ match: { params } }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [isMissingCriticalData, setMissingCriticalData] = useState(false);
  const [modalContent, setModalContent] = useState();
  const samplingLocationId = params.id;

  const {
    data: samplingLocation,
    isLoading: isSamplingLocationLoading,
    refetch: refetchSamplingLocation,
  } = useSamplingLocation({ id: samplingLocationId });

  const { mutate: updateSamplingLocation } = useUpdateSamplingLocation({
    email: "false",
  });

  const { data: states } = useStateList();
  const { data: counties } = useCountyList();

  const handleUpdateReviewStatus = (vals, onSuccess, onError) =>
    updateSamplingLocation(
      { id: samplingLocationId, ...vals },
      {
        onSuccess: () => {
          refetchSamplingLocation();
          onSuccess();
        },
        onError,
      }
    );

  const { data: organization, isLoading: isOrgLoading } = useOrganization(
    {
      id: samplingLocation?.organization?.id,
    },
    { enabled: !!samplingLocation?.organization?.id }
  );

  useEffect(() => {
    setModalContent(
      <SamplingLocationForm
        callback={refetchSamplingLocation}
        isInternal={true}
        internalSamplingLoc={samplingLocation}
        internalOrg={organization}
      />
    );
  }, [organization, samplingLocation, refetchSamplingLocation]);

  useEffect(() => {
    const isMissingData = missingAny(samplingLocation, [
      "estimated_population_served",
      "npdes_id",
      "county_id",
    ]);
    setMissingCriticalData(isMissingData);
  }, [samplingLocation]);

  const tableCells = [
    [
      {
        title: "Org ID*",
        value: samplingLocation?.organization?.display_id,
      },
      {
        title: "Organization Name*",
        value: samplingLocation?.organization?.organization_name,
      },
      {
        title: "Location Type*",
        value: _.startCase(samplingLocation?.location_type),
      },
      {
        title: "Sampling Location Name*",
        value: samplingLocation?.sampling_location_name,
      },
    ],
    [
      {
        title: "State*",
        value: _.find(
          states,
          (item) => item.state_id === samplingLocation?.state_id
        )?.state_name,
      },
      {
        title: "City*",
        value: samplingLocation?.city,
      },
      {
        title: "Zipcode*",
        value: samplingLocation?.zip_id,
      },
      {
        title: "Tribal Nation",
        value: _.startCase(organization?.recognized_tribal_indian_territory),
      },
    ],
    [
      {
        title: "Primary County Name*",
        value: _.find(
          counties,
          (item) => item.county_id === samplingLocation?.county_id
        )?.county_name,
      },
      {
        title: "Primary County Fipscode*",
        value: _.find(
          counties,
          (item) => item.county_id === samplingLocation?.county_id
        )?.fips_cd,
      },
      {
        title: "Catchment Intersections",
        value: samplingLocation?.counties_served,
      },
      {
        title: "Catchment Notes",
        value: samplingLocation?.notes,
      },
    ],
    [
      {
        title: "Estimated Population Served*",
        value: samplingLocation?.estimated_population_served,
      },
      {
        title: "Source of Population Estimate",
        value: samplingLocation?.population_served_source,
      },
      {
        title: "NPDES ID",
        value: samplingLocation?.npdes_id,
      },
      {
        title: "Collection Site Details*",
        value: samplingLocation?.sampling_site_type_id?.display_text,
      },
    ],
    [
      {
        title: "WWTP Design Capacity (MGD)*",
        value: samplingLocation?.capacity_mgd,
      },
      {
        title: "Percent influent from residential (%)",
        value: samplingLocation?.influent_residential_perc,
      },
      {
        title: "Percent influent from separated sewer (%)",
        value: samplingLocation?.influent_sewer_perc,
      },
    ],
    [
      {
        title: "First Name",
        value: samplingLocation?.author_id?.first_name,
      },
      {
        title: "Last Name",
        value: samplingLocation?.author_id?.last_name,
      },
      {
        title: "Email",
        value: samplingLocation?.author_id?.email,
      },
    ],
    [
      {
        title: "NWSS Site Id",
        value: samplingLocation?.nwss_site_id,
      },
      {
        title: "EPA Registry ID",
        value: samplingLocation?.epa_registry_id,
      },
    ],
  ];

  return isSamplingLocationLoading || isOrgLoading ? (
    <Loader />
  ) : (
    <div className="admin-sample-location-page">
      <Modal
        visible={modalOpen}
        width={"75%"}
        footer={[]}
        onCancel={() => setModalOpen(false)}
        destroyOnClose
      >
        {modalContent}
      </Modal>
      <div className="mx-4">
        <div className="title flex">
          <Typography.Title
            level={2}
            style={{
              marginBottom: "40px",
              marginLeft: "-9.5px",
              marginRight: "10px",
            }}
          >
            {samplingLocation?.sampling_location_name}
          </Typography.Title>
          {isMissingCriticalData ? (
            <Alert
              message="Missing Critical Data"
              type="error"
              icon={<AlertOutlined />}
              style={{ height: "36px", userSelect: "none" }}
              showIcon
            />
          ) : null}
        </div>
        <MetadataDetailTable
          title={samplingLocation?.sampling_location_name}
          tableCellsArray={tableCells}
          tableKey="SamplingLocation"
        />
        <Button
          type="primary"
          style={{ margin: "32px -9.5px" }}
          onClick={() => setModalOpen(true)}
        >
          Edit Sample Location Metadata
        </Button>
      </div>
      <div className="m-4">
        <Typography.Title level={2}>Program Enrollment</Typography.Title>
        <ProgramEnrollmentTable samplingLocationId={samplingLocationId} />
      </div>
      <ReviewStatusForm
        onFinishCallback={handleUpdateReviewStatus}
        item={samplingLocation}
      />
    </div>
  );
}

export default SamplingLocation;
