import Selector from "./Selector";

const selectorOptions = [
  { label: "Lab Rerun", value: "LAB_RERUN" },
  { label: "Internal Reissue", value: "INTERNAL_REISSUE" },
  { label: "External Reissue", value: "EXTERNAL_REISSUE" },
];

const selectorText = [
  `If this kit requires reprocessing by the lab, select Lab re-run.`,
  `If this is a report rework, please specify whether this reissue is an
  external or internal reissue.`,
  `External reissues are for reports that were previously sent to the
  customer and have requested reprocessing (i.e. due to missing
  information on the sampling log, etc.).`,
  `Internal reissues are for reports that have not been sent out to the
  customers (i.e. reports that were skipped during processing due to
  missing logs, etc.)`,
];

export default function ReworkTypeSelector({ className }) {
  return (
    <Selector
      label="Type of Rework"
      options={selectorOptions}
      selectorText={selectorText}
      className={className}
      formItem={{
        placeholder: "Select Type",
        width: "66%",
        name: "type_of_rework",
        rules: [{ required: true, message: "Select Type" }],
      }}
    />
  );
}
