import { Col, Row } from "antd";
import Text from "antd/lib/typography/Text";

export function FailedOrderSearch({ error }) {
  return (
    <Row className="mb-5 p-2" style={{ background: "lightpink" }} data-cy="failed_order_search">
      <Col span={24}>
        <Text>
          An error occurred searching for the order:{" "}
          {error.response?.data?.message ??
            error.message ??
            "An unknown error occurred."}
        </Text>
      </Col>
    </Row>
  );
}
