import _ from "lodash";
import { Tag } from "antd";

// Use the assay name to determine how to color code the tag
const ASSAY_COLOR_MAPPING = {
  COVID: "green",
  NORO: "purple",
  HRS: "red",
  "Flu-RSV": "blue",
  MPOX: "gold",
};

// products should be a list of product objects (ie. kit.products)
export default function KitAssays({ products }) {
  const uniqueAssays = _.uniqBy(products, (p) => p.assay?.id);

  return (
    <>
      {_.map(uniqueAssays, (product, index) => (
        <>
          <Tag color={ASSAY_COLOR_MAPPING[product.assay?.name]}>
            {product.assay?.name ?? `Unknown assay for ${product.product_sku}`}
          </Tag>
          {index == uniqueAssays.length - 1 ? "" : <br />}
        </>
      ))}
    </>
  );
}
