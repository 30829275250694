import {
  Form,
  Upload,
  Button,
  Row,
  Col,
  Select,
  Input,
  Tooltip,
  message,
} from "antd";
import { UploadOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { requiredRule } from "utils/rules";
import { PLATE_NAME_OPTIONS } from "api/fileUpload";
import { customRule } from "utils/rules";

const SingleUploadFormFields = ({
  acceptTypes,
  batchFieldIdx,
  uploadFieldIdx,
  onUpload,
  onRemove,
  ...restField
}) => {
  const fieldPath = ["batches", batchFieldIdx, "uploads", uploadFieldIdx];

  const renderDropdownMenu = (menu) =>
    menu.props?.options?.length ? (
      menu
    ) : (
      <div className="px-1">
        To add aliquots, type the shipping tube ID and press "Tab", or paste in
        comma-separated values
      </div>
    );

  const handleUploadChange = (info) => {
    const file = info?.file;
    if (file?.status === "done") {
      onUpload(file?.originFileObj || file, fieldPath);
    } else if (file?.status === "error") {
      message.error("Failed to upload file. Please try again.");
    }
  };

  /**
   * Restrict the number of targets and controls that a given batch can contain
   */
  const ruleSingleTargetControlConstraint = customRule((_, fields) => {
    const batchSize = fields.batches.length;
    if (batchSize === 0 || batchSize > 3) {
      return false;
    }

    for (const batch of fields.batches) {
      let targetSum = 0;
      let controlSum = 0;
      for (const upload of batch.uploads) {
        if (upload.plate_name.includes("targets")) {
          targetSum++;
        }
        if (upload.plate_name.includes("controls")) {
          controlSum++;
        }
      }

      if (targetSum !== 1 || controlSum !== 1) {
        return false;
      }
    }

    return true;
  }, "A batch must contain one control and one target each");

  return (
    <Row>
      <Col flex="auto">
        <Row gutter={16}>
          {/* Plate Name */}
          <Col span={4}>
            <Form.Item
              {...restField}
              label="Plate Name"
              name={[uploadFieldIdx, "plate_name"]}
              rules={[
                requiredRule("Plate Name is required"),
                ruleSingleTargetControlConstraint,
              ]}
            >
              <Select options={PLATE_NAME_OPTIONS} />
            </Form.Item>
          </Col>

          {/* Flagged Aliquots */}
          <Col span={6}>
            <Form.Item
              name={[uploadFieldIdx, "flagged_aliquots"]}
              label="Flagged Aliquots"
              {...restField}
            >
              <Select
                mode="tags"
                tokenSeparators={[","]}
                dropdownRender={renderDropdownMenu}
              />
            </Form.Item>
          </Col>

          {/* Notes */}
          <Col span={6}>
            <Form.Item
              label="Notes"
              name={[uploadFieldIdx, "notes"]}
              {...restField}
            >
              <Input.TextArea rows={1} />
            </Form.Item>
          </Col>

          {/* Uploaded File */}
          <Col span={6}>
            <Form.Item
              label="File"
              name={[uploadFieldIdx, "uploaded_file"]}
              rules={[requiredRule("Must choose file to upload")]}
              {...restField}
            >
              <Tooltip
                title={`Accepts only ${acceptTypes || ".xlsx"} files`}
                placement="bottom"
              >
                <Upload
                  accept={acceptTypes || ".xlsx"}
                  maxCount={1}
                  onChange={handleUploadChange}
                  customRequest={({ onSuccess }) => onSuccess("ok")}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
      </Col>

      {/* Separate Col grouping to prevent wrapping */}
      <Col>
        <MinusCircleOutlined onClick={onRemove} style={{ marginTop: "40px" }} />
      </Col>
    </Row>
  );
};

export default SingleUploadFormFields;
