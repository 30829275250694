import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

import _ from "lodash";
import React from "react";

const { Option, OptGroup } = Select;

export const InputField = ({
  label,
  name,
  rules,
  id,
  placeholder,
  ...props
}) => {
  return (
    <Form.Item label={label} name={name} rules={rules} {...props}>
      <Input id={id} placeholder={placeholder} />
    </Form.Item>
  );
};

export function trackingNumberLink(recordString) {
  const records = recordString?.toString()?.split(",") || [
    recordString?.toString(),
  ];

  return records.map((r) => {
    const trackingNumber = r?.trim() || r;
    const firstChar = trackingNumber
      ? trackingNumber.charAt(0).toUpperCase()
      : null;

    let href = null;

    if (firstChar === "B") {
      // If first letter in the tracking id starts with B, the kit was shipped with mercury
      href = "https://www.shipmercury.com/tracking?TrackingNo=";
    } else if (firstChar === "F" || trackingNumber?.length === 12) {
      // If first letter in the tracking id starts with F or the tracking length is 12, the kit was likely shipped with fedex
      href = "https://www.fedex.com/fedextrack/?trknbr=";
    } else if (trackingNumber?.length <= 22 && trackingNumber?.length >= 20) {
      // If tracking number length is between 20-22 it was likely shipped with USPS
      href =
        "https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=";
    } else if (trackingNumber?.length === 18) {
      // If tracking number length is 18 it was likely shipped with UPS
      href = "https://www.ups.com/mobile/track?trackingNumber=";
    }

    if (href) {
      return (
        <span>
          <a
            href={`${href}${trackingNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {trackingNumber}
          </a>
          <br />
        </span>
      );
    } else {
      return trackingNumber;
    }
  });
}

export const VersionSelector = ({
  name,
  label,
  versions = [],
  versionNumberPrefix,
  groupByAssay = false,
}) => {
  const assayGroups = groupByAssay
    ? _.groupBy(versions, ({ version_name }) =>
        version_name.slice(0, version_name.indexOf("-"))
      )
    : null;

  /**
   * Pulls version number from version_name fields (mpx-v.4.1.2 => 4.1.2)
   * and sorts list of versions by that version number for that assay
   * @param {{ version_name: string; description: string }} a : n-1 version e.g. { version_name: 'mpx-v4.0' }
   * @param {{ version_name: string; description: string }} b : n version e.g. { version_name: 'mpx-v4.1.2' }
   * @returns {number} Array.prototype.sort return value
   */
  const vNameDesc = (a, b) => {
    a = a.version_name?.split(versionNumberPrefix)[1] ?? "0";
    b = b.version_name?.split(versionNumberPrefix)[1] ?? "0";
    return b
      .replace(/\d+/g, (n) => +n + 100000)
      .localeCompare(a.replace(/\d+/g, (n) => +n + 100000));
  };

  const getOptionList = (versions) =>
    (versions || []).sort(vNameDesc).map((v) => (
      <Option key={v.id} value={v.id}>
        <i>{v.version_name}</i>
        {v.description && <span>{` \u{2192} ${v.description}`}</span>}
      </Option>
    ));

  return (
    <Form.Item name={name} label={label}>
      <Select allowClear={true} style={{ "max-width": "300px" }}>
        {groupByAssay ? (
          <>
            {assayGroups.mpx && (
              <OptGroup label="Monkeypox">
                {getOptionList(assayGroups.mpx)}
              </OptGroup>
            )}
            {assayGroups.covid && (
              <OptGroup label="SARS-CoV-2">
                {getOptionList(assayGroups.covid)}
              </OptGroup>
            )}
            {assayGroups.noro && (
              <OptGroup label="Norovirus">
                {getOptionList(assayGroups.noro)}
              </OptGroup>
            )}
            {assayGroups.resp && (
              <OptGroup label="Respiratory">
                {getOptionList(assayGroups.resp)}
              </OptGroup>
            )}
            {assayGroups.hrs && (
              <OptGroup label="HRS">{getOptionList(assayGroups.hrs)}</OptGroup>
            )}
          </>
        ) : (
          getOptionList(versions)
        )}
      </Select>
    </Form.Item>
  );
};

export const UploadResultTable = ({ uploadResults }) => {
  // group by batches
  const groupedResults = _.groupBy(uploadResults, (result) =>
    _.get(result, ["value", "batch_name"])
  );
  return (
    <Space direction="vertical" size="large" className="w-100">
      {_.map(groupedResults, (resultsPerBatch, batchName) => (
        <div key={batchName}>
          <Typography.Title level={5}>{batchName}</Typography.Title>
          {resultsPerBatch.map(({ value: result }, rIdx) => (
            <Row key={`${batchName}-${rIdx}`} className="pl-1">
              {result?.plate_name && <Col span={8}>{result?.plate_name}</Col>}
              <Col span={14}>{result?.uploaded_file?.name}</Col>
              <Col span={2}>
                {result?.success ? (
                  <CheckCircleFilled style={{ color: "#00c783" }} />
                ) : (
                  <Tooltip
                    title={`${
                      result?.error || "Upload failed, please try again."
                    }`}
                    color="red"
                  >
                    <ExclamationCircleFilled style={{ color: "red" }} />
                  </Tooltip>
                )}
              </Col>
            </Row>
          ))}
        </div>
      ))}
    </Space>
  );
};
