import { Button, Col, Form, Input, message, Row, Tooltip, Upload } from "antd";
import { MinusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  useLcmsLabProtocolVersionList,
  useLcmsAnalysisProtocolVersionList,
} from "api/fileUpload";
import { VersionSelector } from "utils/components";
import { requiredRule } from "utils/rules";

const SingleUploadFormFields = ({
  uploadFieldIdx,
  onUpload,
  onRemove,
  ...restField
}) => {
  const fieldPath = ["uploads", uploadFieldIdx];
  const { data: labProtocolVersions } = useLcmsLabProtocolVersionList();
  const {
    data: analysisProtocolVersions,
  } = useLcmsAnalysisProtocolVersionList();

  const handleUploadChange = (info) => {
    const file = info?.file;
    if (file?.status === "done") {
      onUpload(file?.originFileObj || file, fieldPath);
    } else if (file?.status === "error") {
      message.error("Failed to upload file. Please try again.");
    }
  };

  return (
    <Row>
      <Col flex="auto">
        <Row gutter={16}>
          {/* Batch Name */}
          <Col span={4}>
            <Form.Item
              name={[uploadFieldIdx, "batch_name"]}
              label="Batch Name"
              rules={[requiredRule("Batch Name is required")]}
            >
              <Input placeholder="e.g. Plate 893A PMMV or Plate893A_PMMV_Cy0" />
            </Form.Item>
          </Col>

          {/* Lab protocol version */}
          <Col span={5}>
            <VersionSelector
              name={[uploadFieldIdx, "lab_protocol_version"]}
              label="Lab Protocol Version"
              versionNumberPrefix="-v"
              groupByAssay={true}
              versions={labProtocolVersions}
              rules={[requiredRule("Lab Protocol Version is required")]}
            />
          </Col>

          <Col span={5}>
            <VersionSelector
              name={[uploadFieldIdx, "analysis_protocol_version"]}
              label="Analysis Protocol Version"
              versionNumberPrefix="v"
              groupByAssay={true}
              versions={analysisProtocolVersions}
              rules={[requiredRule("Analysis Protocol Version is required")]}
            />
          </Col>

          {/* Notes */}
          <Col span={4}>
            <Form.Item
              label="Notes"
              name={[uploadFieldIdx, "notes"]}
              {...restField}
            >
              <Input.TextArea rows={1} />
            </Form.Item>
          </Col>

          {/* Uploaded File */}
          <Col span={5}>
            <Form.Item
              label="File"
              name={[uploadFieldIdx, "uploaded_file"]}
              rules={[requiredRule("Must choose file to upload")]}
              {...restField}
            >
              <Tooltip title="Accepts only .csv files" placement="bottom">
                <Upload
                  accept=".csv"
                  maxCount={1}
                  onChange={handleUploadChange}
                  customRequest={({ onSuccess }) => onSuccess("ok")}
                >
                  <Button icon={<UploadOutlined />}>Upload</Button>
                </Upload>
              </Tooltip>
            </Form.Item>
          </Col>
        </Row>
      </Col>

      {/* Separate Col grouping to prevent wrapping */}
      <Col>
        <MinusCircleOutlined onClick={onRemove} style={{ marginTop: "40px" }} />
      </Col>
    </Row>
  );
};

export default SingleUploadFormFields;
